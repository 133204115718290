<template>
  <div class="wrap">
    <div class="main">
      <div class="imgs">
        <img :src="item" @click="handleClickItem" v-for="(item, idx) in imgs" :key="idx" alt="" class="img">
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import { showConfirmDialog, showNotify } from 'vant';
import { mapActions, mapState } from 'vuex';
import dayjs from 'dayjs';
import axios from 'axios';
export default {
  name: 'Fwtc',
  data() {
    return {
      active: 0,
      imgs:[],
    }
  },
  created() {

  },
  mounted() {
    this.getData();
  },
  computed: {
  },
  methods: {
    async getData() {
      let { data:res } = await axios.get('/api/api/get_yxsc_data', {
        sjhm: this.$route.query.sjhm,
      });
      let id = this.$route.query.id || '';
      let current = res.data.find(f=>f.id == id);
      this.$nextTick(()=>{
        this.imgs = current?.detail_img.split(',') || [];
      })
    },
    handleClickItem() {
      this.$router.back();
    },
  },

}
</script>
<style lang="less" scoped>
.wrap {
  .main {
    // margin-top: 24px;

    .imgs {
      width: 100%;

      .img {
        vertical-align: top;
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>
